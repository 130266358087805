.admin-navbar .dropdown-container{
    cursor: pointer;
}
.admin-navbar .nav-bar-dropdown{
    position: absolute;
    z-index: 15;
   display: none;
    width: 300px;
    right: 0;
    background: #000;
    padding:5px 15px;
    border-radius: 10px;

}
.admin-navbar .dropdown-container:hover .nav-bar-dropdown {
display: flex;
}
.admin-navbar .nav-bar-dropdown p{
    margin: 0;
    padding: 5px 10px;
    transition: 0.5s;
    
}
.admin-navbar .nav-bar-dropdown p:hover{
    color:red;
    
}